const prodUrl = "https://7urpjeftp2.execute-api.eu-central-1.amazonaws.com/prod"
const devUrl = "https://jcse1uooda.execute-api.eu-central-1.amazonaws.com/dev"
const url = process.env.NODE_ENV === "production" ? prodUrl : devUrl;

const prodBucket = "bogb-ranking-and-matches-prod";
const devBucket = "bogb-ranking-and-matches-dev";
const bucket = process.env.NODE_ENV === "production" ? prodBucket : devBucket;

export {
    url,
    bucket
}